.queryBuilder {
    min-width: 350px;
    border: 0px solid #0000001f;
    /* padding-bottom: 10px; */
    /* margin: 5px; */
}

.ruleGroup {
    border: 1px solid #0000001a;
    border-radius: 4px;
}

.ruleGroup, .ruleGroup .ruleGroup-body {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem !important;
    padding: 5px;
}

.ruleGroup .rule, .ruleGroup .ruleGroup-header {
    align-items: center;
    column-gap: 1rem !important;
    display: flex;
    /* padding: 10px; */
    /* background-color: #fff; */
}

.queryBuilder-dragHandle {
    display: none;
}

.rule-valueSource {
    display: none !important;
}

.ruleGroup-combinators, .rule-fields, .rule-operators, .rule-value, .rule-valueSource {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0px;
    border: 0px;
    vertical-align: top;
    font-size: 0.8125rem;
    /* padding: 4px 10px 4px 0; */
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    outline: none;
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    min-width: 16px;
    user-select: none;
    border-radius: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 30px;
    font-size: 1rem;    
}

.ruleGroup-combinators option, .rule-fields option, .rule-operators option, .rule-value option {
    background-color: rgba(0, 0, 0, 0.08) !important;
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    min-height: 48px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px
}

.ruleGroup-combinators option:hover, .rule-fields option:hover, .rule-operators option:hover, .rule-value option:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.ruleGroup-combinators::after, .rule-fields::after, .rule-operators::after, .rule-value::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
}

.ruleGroup-addRule, .ruleGroup-addGroup, .rule-remove, .ruleGroup-remove {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    /* min-width: 10px; */
    padding: 4px 10px;
    border-radius: 4px;
    /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    color: rgb(255, 255, 255);
    background-color: #3f51b5;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    cursor: pointer;
    opacity: 0.9;
}

.ruleGroup-addRule:hover, .ruleGroup-addGroup:hover {
    background-color: #3f51b5;
    opacity: 1;
}

.ruleGroup .ruleGroup {
    background-color: #0000000d;
    border: 1px solid 0000000d;
    border-radius: 4px;
    padding: 10px;
} 

.rule-remove, .ruleGroup-remove {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: none;
    opacity: 0.8;
}

.rule-remove:hover, .ruleGroup-remove:hover {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: none;
    opacity: 1;
}

.truthyValues, .falsyValues {
    background-color: #3f51b559;
    text-align: center;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 500;
    display: none;
}