body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa {
  width: 100% !important;
  margin: 2px;
}

.validation-disabled {
  color: rgba(0, 0, 0, 0.38)!important;
}

.latestVersion {
  float: right;
  color: #6c757d;
  font-style: italic;
}

.MuiTreeView-root span.material-icons {
  width: 25px !important;
  font-size:20px
}

.align-items-start {
  align-items: start !important;
}

.word-break-all span {
  word-break: break-all;
}

.context-layout-width {
  width: 100% !important;
  margin: 0 !important;
}

.Mui-expanded .MuiTreeItem-group {
  margin-left: 30px;
}

.dis-settings-toggle-panel {
  margin-bottom: 10px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.create-rule {
  background-color: #3f51b5 !important;
  color: #ffffff !important;
}

.accordianTitle {
  width: '100%';
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
}

.ruleList .MuiPaper-elevation1 {
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 20%), 0px 2px 2px 2px rgb(0 0 0 / 14%), 0px 2px 3px 2px rgb(0 0 0 / 12%) !important;
}

.dropZone { 
  border: 2px dashed rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: center;
  height: 150px;
  align-items: center;
}

.ruleListItems {
  margin-bottom: 10px;
  padding-right:80px;
  border: 1px solid rgba(0, 0, 0, 0.14)
}

.appliedListItems {
  margin-bottom: 10px;
  padding-right:80px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  background-color: #d3cece;
}