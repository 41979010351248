.dragEnter{
  background-color: #BBB;
  border: 1px #888 dashed;
}
.react-contextmenu--visible{
  background: #FFF;
  border: 1px #CCC solid;
  min-width: 200px;
  font-weight: bold;
  z-index: 9999;
}
.react-contextmenu-item{
  padding: 7px 15px;
}
.react-contextmenu-item:hover{
  cursor: pointer;
  background: #EEE;
}
.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}