.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageEditorCard {
  width: 33%;
  min-width: 350px;
}

.triggerEditorCard {
  width: 50%;
  min-width: 350px;
}

.ruleEditorCard {
  width: 100%;
  min-width: 1080px;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
}

.radio-main-div {
  display: flex;
  align-items: center;
}
.radio-main-div .inline-radio {
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-right: 10px;
  font-size: 14px;
}
.radio-main-div .inline-radio input {
  margin-top: 0px;
}

.add-field-dialog li {
  padding-top: 2px;
  padding-bottom: 2px;
}
.add-field-dialog .MuiSvgIcon-root {
  width: 0.8em;
  height: 0.7em;
}
.add-field-dialog .MuiListItemIcon-root {
  min-width: 30px;
}

.add-field-modal-parent .tab-left {
  min-width: 165px;
}

.add-field-modal-parent .tab-right {
  width: 100%;
}
.add-field-modal-parent .modal-title h2 {
  width: 100%;
}
.add-field-modal-parent .add-field-text {
  display: inline-block;
  margin: 0px;
}
.add-field-modal-parent .info-indicator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-field-modal-parent .info-indicator h4, .add-field-modal-parent .info-indicator p{
  margin:0px;
}
.add-field-modal-parent .info-indicator .info-icon {
  float: left;
  width: 30px;
  height: 15px;
  background: #d3cece;
  margin-right: 0px;
  margin-top: 11px;
}
.add-field-modal-parent .info-indicator .info-text {
  font-size: 14px;
  line-height: 0;
  font-weight: 500;
  padding-left: 10px;
}
.add-field-modal-parent .info-indicator .info-main {
  /* float: right; */
  margin: 0px;
}

.field-extis {
  background-color: #d3cece;
}
.tab-left .field-extis span.MuiTab-wrapper{
  background-color: #d3cece;
  padding: 2px;
}
.tab-left .MuiTabs-indicator {
  top: 55px;
  height: 32px;
}
.highlight-delete-title {
  color: #3F51B5;
  font-weight: 600;
}
.info-indicator-global-setting .info-icon {
  float: left;
  width: 45px;
  height: 15px;
  background: #d3cece;
  margin-right: 10px;
  margin-top: 0px;
}
.info-indicator-global-setting p div{
  align-items: center;
  display: flex;
}
.varialble-input input{
  font-weight: 500;
}
.d-none {
  display: none !important;
}

.overlayComponent {
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.38);
}

.w-35 {
  width: 35%;
}

.tooltipHeader {
  font-size: 13px; 
  font-weight: bolder;
}

.tooltipContent {
  font-size: 12px; 
}